<template>
    <layout>
        <div class="card">
            <div class="card-header">
                <div class="d-flex align-items-center justify-content-between">
                    <h4 class="card-title">{{ $t('invoices.deposit') }}</h4>
                </div>
            </div>
            <div class="card-body px-3 bg-white">
                <deposit-form></deposit-form>
            </div>
            <div class="card-body">

            </div>
        </div>
        <b-card body-class="p-0 pb-3 p-md-3">
            <template #header>
                <b-card-title>{{ $t('invoices.title') }}</b-card-title>
            </template>
            <!-- Table -->
            <div class="table-responsive mb-3 mb-md-0">
                <b-table
                    :current-page="currentPage"
                    :fields="fields"
                    :items="items"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    hover
                    :busy="loading"
                    responsive="sm"
                    show-empty
                    :empty-text="$t('table.empty_result')"
                >
                    <template #table-busy>
                        <div class="text-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('table.loading') }}...</span>
                            </div>
                        </div>
                    </template>
                    <template #cell(is_paid)="data">
                        <i class="fas" :class="{'fa-check-circle text-success': data.value === 't', 'fa-times-circle text-danger': data.value === 'f'}"></i>
                    </template>
                    <template #cell(actions)="data">
                        <div class="no-wrap">
                            <b-button v-if="data.item.is_printable === 't'" v-b-tooltip.hover.top="$t('table.print')" class="me-1" size="sm" variant="primary" @click="print(data.item.id)">
                                <i class="fas fa-print"></i>
                            </b-button>
                            <b-button v-if="data.item.is_printable === 't'" v-b-tooltip.hover.top="$t('table.download')" class="me-1" size="sm" variant="dark" @click="download(data.item.id)">
                                <i class="fas fa-download"></i>
                            </b-button>
                        </div>
                    </template>
                </b-table>
            </div>
            <div class="row">
                <div class="col">
                    <div class="d-flex w-100 align-items-center justify-content-center justify-content-md-end">
                        <div
                            class="dataTables_paginate paging_simple_numbers"
                        >
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                ></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </b-card>
    </layout>
</template>

<script>
import Layout from "../../layouts/table";
import {mapGetters} from "vuex"
import DepositForm from './deposit.form.vue'
import moment from "moment";

export default {
    name: "InvoicesIndex",

    components: {DepositForm, Layout},

    data() {
        return {
            perPage: 10,
            currentPage: 1,
            sortBy: 'date',
            sortDesc: true,
            formatter: new Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                maximumSignificantDigits: 1
            }),
            modal: false
        };
    },

    computed: {
        ...mapGetters({
            items: 'invoice/list',
            item: 'invoice/item',
            loading: 'invoice/loading'
        }),

        rows: function () {
            return this.items && this.items.length
        },

        fields: function () {
            return [
                {
                    key: "id",
                    label: this.$t('invoices.id'),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "invoice_number",
                    label: this.$t('invoices.number'),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "invoice_type",
                    label: this.$t('invoices.subject'),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "create_date",
                    label: this.$t('invoices.date'),
                    thClass: 'border-left-0',
                    formatter: (value) => moment(value).format('llll'),
                    sortable: true
                },
                {
                    key: "invoice_amount",
                    label: this.$t('invoices.amount'),
                    thClass: 'border-left-0',
                    tdClass: 'text-end',
                    formatter: (value) => this.$currencyFormatter.format(value),
                    sortable: true
                },
                {
                    key: "is_paid",
                    label: this.$t('invoices.is_paid'),
                    thClass: 'border-left-0',
                    tdClass: 'text-center',
                    sortable: true
                },
                {
                    key: "actions",
                    label: '',
                    thClass: '',
                    tdClass: 'text-end'
                },
            ];
        },
    },

    methods: {
        print: function (id) {
            this.$store.dispatch('invoice/print', id);
        },

        download: function (id) {
            this.$store.dispatch('invoice/download', id);
        },
    },

    created() {
        document.title = this.setTitle(this.$t('invoices.title'));
        this.$store.dispatch('invoice/fetchList');
    }
}
</script>

<style scoped>

</style>
